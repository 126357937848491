import OberloTypography from '@/components/pages/oberlo/components/OberloTypography/Typography';
import Pagination from '@/components/pages/oberlo/components/Pagination/Pagination';
import {Breadcrumbs} from '@/components/pages/oberlo/components/Breadcrumbs/Breadcrumbs';
import type {BreadcrumbsList} from '@/components/pages/oberlo/components/Breadcrumbs/Breadcrumbs';
import type {PaginationProps} from '@/components/pages/oberlo/components/Pagination/Pagination';

interface TopicHeaderProps {
  className?: string;
  header: string;
  breadcrumbsList: BreadcrumbsList[];
  paginationProps: PaginationProps;
}

export default function TopicHeader({
  className,
  header,
  breadcrumbsList,
  paginationProps,
}: TopicHeaderProps) {
  return (
    <div className={className}>
      <Breadcrumbs list={breadcrumbsList} classList="flex justify-start" />
      <OberloTypography
        as="h1"
        className="text-display-lg font-display uppercase lg:text-size-display-lg text-blue-10 flex"
      >
        {header}
      </OberloTypography>
      <Pagination {...paginationProps} />
    </div>
  );
}
