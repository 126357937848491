import {useParams} from '@remix-run/react';
import {useState, useEffect} from 'react';
import compact from 'lodash/compact';

import {useSharedTranslations, useTranslations} from '@/hooks/useTranslations';
import Image from '@/components/pages/oberlo/components/Image/Image';
import type {Pagination} from '@/components/pages/oberlo/types';

import {getStartingPage} from '../../utils/utils';

export interface PaginationProps {
  pagination: Pagination;
  baseUrl: string;
  secondaryUrl: string;
}

export default function Pagination({
  pagination,
  baseUrl,
  secondaryUrl,
}: PaginationProps) {
  const {t} = useTranslations();
  const {t: tSettings} = useSharedTranslations('pages/pagination_settings');
  const params = useParams();
  const [currentPage, setCurrentPage] = useState(
    getStartingPage(params.page, 2),
  );
  const [localPagination, setPagination] = useState(pagination);

  const handlePageClick = (pageNumber: number) => {
    if (pageNumber === 1) {
      window.location.href = baseUrl;
    } else {
      window.location.href = `${secondaryUrl}/${pageNumber}`;
    }
    window.scrollTo(0, 0);
  };

  const arrow = tSettings('images.pagination.image.en');
  const paginationIsPresent =
    compact(Object.values(localPagination)).length > 0;

  useEffect(() => {
    const handleChangePushState = (event: any) => {
      setCurrentPage(event.detail.page);
      setPagination(event.detail.pagination);
    };

    window.addEventListener('paginationPageChange', handleChangePushState);

    return () => {
      window.removeEventListener('paginationPageChange', handleChangePushState);
    };
  }, []);

  return (
    <ul className="flex flex-wrap text-gray-70 mt-4">
      {paginationIsPresent && (
        <li className="flex justify-center items-center p-2">
          <a
            href={
              currentPage === 2 ? baseUrl : `${secondaryUrl}/${currentPage - 1}`
            }
          >
            <Image
              className="w-8 h-4 rotate-180"
              src={arrow.src}
              alt={arrow.alt}
            />
            <span className="sr-only">{t('pagination.prevPage')}</span>
          </a>
        </li>
      )}
      {localPagination?.hasPreviousPage && (
        <li className="p-2">
          <button onClick={() => handlePageClick(currentPage - 1)}>
            {currentPage - 1}
          </button>
        </li>
      )}
      {paginationIsPresent && (
        <li className="p-2">
          <a
            href={`${secondaryUrl}/${currentPage}`}
            onClick={() => handlePageClick(currentPage)}
            className="text-black font-bold"
          >
            {currentPage}
          </a>
        </li>
      )}
      {localPagination?.hasNextPage && (
        <li className="p-2">
          <a
            href={`${secondaryUrl}/${currentPage + 1}`}
            onClick={() => handlePageClick(currentPage + 1)}
          >
            {currentPage + 1}
          </a>
        </li>
      )}
      {localPagination?.hasThirdPage && (
        <li className="p-2">
          <a
            href={`${secondaryUrl}/${currentPage + 2}`}
            onClick={() => handlePageClick(currentPage + 2)}
          >
            {currentPage + 2}
          </a>
        </li>
      )}
      {localPagination.hasNextPage && (
        <li className="flex justify-center items-center p-2">
          <a href={`${secondaryUrl}/${currentPage + 1}`}>
            <Image className="w-8 h-4" src={arrow.src} alt={arrow.alt} />
            <span className="sr-only">{t('pagination.nextPage')}</span>
          </a>
        </li>
      )}
    </ul>
  );
}
