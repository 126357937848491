/* eslint-disable react-hooks/exhaustive-deps */
import {useFetcher, useParams} from '@remix-run/react';
import type {RefObject} from 'react';
import {useState, useEffect} from 'react';

import {getStartingPage} from '../utils/utils';
import type {ShopifyOberloData} from '../types';

type useShowMoreArgs = {
  fetchUrl: string;
  elementRef: RefObject<HTMLElement>;
  initialData: ShopifyOberloData[];
};

export const useShowMore = ({
  fetchUrl,
  elementRef,
  initialData,
}: useShowMoreArgs) => {
  const fetcher = useFetcher<any>();
  const params = useParams();
  const [page, setPage] = useState(getStartingPage(params.page));
  const [data, setData] = useState([...initialData]);

  const handleShowMore = () => {
    fetcher.load(`${fetchUrl}/${page + 1}`);

    if (elementRef.current) {
      elementRef.current.blur();
    }
  };

  useEffect(() => {
    if (fetcher.data) {
      setData((prevBlogs: ShopifyOberloData[]) => [
        ...prevBlogs,
        ...fetcher.data.processedData?.articles,
      ]);
      setPage((prevPage: number) => prevPage + 1);
      const eventData = {
        detail: {
          page: page + 1,
          pagination: fetcher.data.processedData?.pagination,
        },
      };

      const paginationEvent = new CustomEvent(
        'paginationPageChange',
        eventData,
      );
      const wrapperEvent = new CustomEvent('wrapperPageChange', eventData);
      window.dispatchEvent(paginationEvent);
      window.dispatchEvent(wrapperEvent);

      window.history.pushState(
        null,
        '',
        `${fetchUrl}/${page === 1 ? 2 : page + 1}`,
      );
    }
  }, [fetcher.data]);

  return {data, fetcherState: fetcher.state, page, handleShowMore};
};
